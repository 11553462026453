<template>
  <div class="suppliers-hero">
    <div class="page-hero container">
      <div class="page-hero__text">
        <h1>{{ $t("vendorTitle") }}</h1>
        <h2>{{ $t("vendorContent") }}</h2>
      </div>

      <div class="page-hero__btns">
        <!-- <a href="/supplier-questionnaire" class="btn">{{ $t("fillForm") }}</a> -->
        <a
          href="https://partner.magnum.kz/auth/login"
          target="_blank"
          class="btn"
          >{{ $t("vendorPortal") }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuppliersHero",
};
</script>
